import { Badge, Rate, Tag, Tooltip } from "antd";
import { LessonType } from "App.types";
import React, { FC } from "react";
import { Levels, STATIC_URL } from "App.constants";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import cx from "classnames";
import styles from "./LessonsItem.module.scss";
import { CheckOutlined, CrownTwoTone, HeartOutlined } from "@ant-design/icons";
import { encodeS3URI } from "App.helpers";

const LessonsItem: FC<{ isLocked?: boolean; isCompleted: boolean; lesson: LessonType; stars?: number }> = ({
  isCompleted,
  isLocked,
  stars,
  lesson: { isActive, level = 0, id, description, type: lessonType, story, grammar },
}) => {
  const navigate = useNavigate();

  const { picture, type, wordCount, title, translate, description: storyDescription } = story ?? {};

  return (
    <Badge.Ribbon
      rootClassName={cx(styles.lessonCard, {
        [styles.lessonCard__locked]: isLocked,
        [styles.mobile]: isMobile,
        [styles.lessonCard__completed]: isCompleted,
      })}
      className={cx({ [styles.hidden]: !Levels[level]?.title })}
      style={{ fontSize: isMobile ? 12 : undefined }}
      text={Levels[level]?.title}
      color={Levels[level]?.color}
    >
      <div
        id={`${id}`}
        className={styles.lessonCard__container}
        // style={{ backgroundImage: `url(${STATIC_URL}/lessons/${story.title?.toLowerCase()}.svg)` }}
        // style={{ backgroundImage: `url(${STATIC_URL}/stories/${story.title}/images/cover.svg)` }}
        onClick={() => navigate(`lessons/${lessonType || type}/${id}`, { state: { scrollPosition: window.scrollY } })}
      >
        <div className={cx(styles.content, { [styles.content__mobile]: isMobile })}>
          {picture ? (
            <img
              className={cx(styles.cover, { [styles.cover__jpg]: picture?.includes("jpg") })}
              alt={""}
              src={`${STATIC_URL}/stories/${encodeS3URI(title)}/${picture}`}
            />
          ) : (
            <img className={cx(styles.cover)} alt={""} src={`${STATIC_URL}/covers/${type}.svg`} />
          )}

          <div className={styles.right}>
            {isCompleted ? (
              <CheckOutlined />
            ) : (
              !!wordCount && (
                <div className={styles.desc}>
                  <CrownTwoTone />
                  {` ${wordCount}`}
                  {!isActive && <Tag color={"red"}>inactive</Tag>}
                </div>
              )
            )}
            <div className={styles.title}>{lessonType === "grammar" ? grammar?.title : title}</div>
            {!isMobile && <div className={styles.desc}>{storyDescription || description || translate}</div>}

            {stars && (
              <Tooltip title={"Оценка выполнения задания"}>
                <Rate className={styles.stars} allowHalf disabled defaultValue={stars} character={<HeartOutlined />} />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {isMobile && <div className={styles.desc}>{storyDescription || description || translate}</div>}
      {/*{!!story.wordCount && <Tag bordered={false}>кол-во слов: {story.wordCount}</Tag>}*/}
    </Badge.Ribbon>
  );
};

export default LessonsItem;

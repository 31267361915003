import cx from "classnames";
import styles from "./GrammarPage.module.scss";
import { isMobile } from "react-device-detect";
import { replaceTemplates } from "App.helpers";
import React, { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import useGrammarAudio from "Hooks/useGrammarAudio";
import { UserContext } from "App";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import ButtonClose from "Components/ButtonClose";
import { Flex } from "antd";

const GrammarPage = () => {
  const { course: courseTitle = "", id } = useParams();

  const { data: lessons = [] } = useSuspenseQuery({
    queryKey: ["lessons", "course", courseTitle],
    queryFn: () => API.lesson.getList(courseTitle),
  });

  const user = useContext(UserContext);

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  const grammar = useMemo(() => (id ? lessons.find((l) => l.id === +id)?.grammar : undefined), [id, lessons]);

  useGrammarAudio(grammar?.content || "");

  return (
    <div className={cx(styles.grammarPage, { [styles.grammarPage__mobile]: isMobile })}>
      <Flex justify={"space-between"}>
        <div className={styles.title}>{grammar?.title}</div>
        <ButtonClose path={`/course/${courseTitle}`} />
      </Flex>

      <div className={styles.content} dangerouslySetInnerHTML={{ __html: replaceTemplates(grammar?.content || "", userProps) }} />
    </div>
  );
};

export default GrammarPage;

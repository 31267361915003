import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { SpeechResultType, StatusType, TagType } from "App.types";
import io, { Socket } from "socket.io-client";
import Cookies from "js-cookie";
import { API_URL } from "App.constants";
import { flatten, uniq } from "lodash";
import { UserContext } from "App";

type Props = {
  tags?: Partial<TagType>[];
  setStatus: Dispatch<SetStateAction<StatusType>>;
  interim_results?: boolean;
  text: string | undefined;
  easy?: boolean;
};

const useSocketSpeech = ({
  setStatus,
  text = "",
  interim_results,
  easy,
}: Props): {
  start: () => any;
  stop: () => any;
  isSpeaking: boolean;
  results: SpeechResultType[];
  reset: () => any;
} => {
  const [results, setResults] = useState<SpeechResultType[]>([]);
  const [isSpeaking, setIsSpeaking] = useState(false);

  const userMedia = useRef<MediaStream>();
  const socket = useRef<Socket>();
  const microphone = useRef<MediaRecorder>();

  const user = useContext(UserContext);

  const token = user?.token || Cookies.get("token");

  const silentTimeoutId = useRef<any>(null);

  const keywords = useMemo(
    () =>
      uniq(
        text?.split(" ").reduce(
          (acc, cur, idx, arr) => {
            return flatten([...acc, Array.from(Array(arr.length).keys()).map((n) => arr.slice(idx, n + 1).join(" "))]);
          },
          [text],
        ),
      ).filter((s) => s),
    [text],
  );

  const onDataAvailable = useCallback(async (event: any) => {
    if (event.data.size > 0 && socket.current?.connected) {
      console.log("audio sent");
      socket.current?.emit("speech", event.data);
    }
  }, []);

  const stop = useCallback(() => {
    console.log("STOP");
    microphone.current?.removeEventListener("dataavailable", onDataAvailable);
    clearTimeout(silentTimeoutId.current);
    setResults([]);
    setIsSpeaking(false);
    socket.current?.disconnect();

    setStatus((prevState) => (prevState !== StatusType.Completed ? StatusType.Empty : prevState));
    microphone.current?.stop();
    if (userMedia.current) userMedia?.current.getTracks().forEach((track) => track.stop());
  }, [setStatus, onDataAvailable]);

  const start = useCallback(async () => {
    setStatus(StatusType.Loading);
    setResults([]);

    if (!socket.current || !socket.current?.connected) {
      socket.current = io(`${API_URL}/ggl`, {
        query: {
          keywords: `${keywords.join(";")}`,
          // keywords: `${text
          //   ?.replace(/[,.!?:-]/g, "")
          //   .split(" ")
          //   .join(";")}`,
          token,
          interim_results,
          easy,
        },
      });

      socket.current.on("connect", async () => {
        console.log("client: connected to websocket");

        userMedia.current = await navigator.mediaDevices.getUserMedia({
          audio: { channelCount: 1, sampleRate: 16000 },
        });
        if (!userMedia) return console.error("no UserMedia available");

        clearTimeout(silentTimeoutId.current);
        silentTimeoutId.current = setTimeout(stop, 8000);
        setStatus(StatusType.IsRecording);

        microphone.current = new MediaRecorder(userMedia.current);

        // microphone.current = new RecordRTC(userMedia.current, {
        //   type: "audio",
        //   mimeType: "audio/webm;codecs=pcm",
        //   recorderType: RecordRTC.StereoAudioRecorder,
        //   timeSlice: 250,
        //   desiredSampRate: 16000,
        //   numberOfAudioChannels: 1,
        //   bufferSize: 4096,
        //   audioBitsPerSecond: 128000,
        //   ondataavailable: (blob) => {
        //     if (!socket.current?.connected) return;
        //     //const buffer = await blob.arrayBuffer();
        //     socket.current?.emit("speech", blob);
        //   },
        // });

        microphone.current?.addEventListener("dataavailable", onDataAvailable);
        microphone.current?.start(500);
      });

      socket.current.on("dg", (message: SpeechResultType[]) => {
        clearTimeout(silentTimeoutId.current);
        silentTimeoutId.current = setTimeout(stop, 8000);

        console.log(
          "dg:",
          message.map((m) => m.transcript),
        );

        setResults(message);
      });

      socket.current.on("disconnect", () => {
        console.log("client: disconnected");
        setIsSpeaking(false);
        microphone.current?.removeEventListener("dataavailable", onDataAvailable);

        setStatus((prevState) => (prevState !== StatusType.Completed ? StatusType.Empty : prevState));
        microphone.current?.stop();
        if (userMedia.current) userMedia?.current.getTracks().forEach((track) => track.stop());
      });

      socket.current.on("SpeechStarted", (message) => {
        setIsSpeaking(message);
      });

      socket.current.on("g", (messages) => {
        clearTimeout(silentTimeoutId.current);
        silentTimeoutId.current = setTimeout(stop, 8000);

        console.log(
          "g: ",
          messages.map((m: SpeechResultType) => m.transcript),
        );

        setResults(messages.map((m: SpeechResultType) => ({ ...m, words: m.transcript.split(" ").map((word) => ({ word })) })));
      });
    }
  }, [setStatus, keywords, token, interim_results, easy, stop, onDataAvailable]);

  useEffect(() => {
    if (socket.current?.connected && text) {
      console.log("RECONNECT");
      setStatus(StatusType.Loading);
      stop();
      setResults([]);
      start();
    }
  }, [text, stop, start, setStatus]);

  const reset = useCallback(() => {
    setResults([]);
  }, []);

  return { start, stop, isSpeaking, results, reset };
};

export default useSocketSpeech;

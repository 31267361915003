import { useNavigate, useParams } from "react-router-dom";
import { FC, useEffect } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";

const LessonRedirect: FC<{}> = () => {
  const { lessonId } = useParams();
  const navigate = useNavigate();

  const { data } = useSuspenseQuery({
    queryKey: ["lessonId", lessonId],
    queryFn: lessonId ? () => API.lesson.get(lessonId) : undefined,
  });

  useEffect(() => {
    if (data) {
      navigate(`/course/${data.course?.title}/lessons/${data.type ?? "exercise"}/${lessonId}`);
    }
  }, [data, lessonId, navigate]);

  return null;
};

export default LessonRedirect;

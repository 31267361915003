import { useContext, useEffect, useMemo } from "react";
import { API_URL, DEFAULT_VOICES } from "App.constants";
import Cookies from "js-cookie";
import { SentenceType } from "App.types";
import { UserContext } from "App";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";

const EMPTY_AUDIO_SRC =
  "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

const useSentenceAudio = ({
  sentence,
  disabled,
  voice,
  isSecond,
}: {
  isSecond?: boolean;
  sentence: SentenceType | undefined;
  disabled?: boolean;
  voice?: string;
}) => {
  const user = useContext(UserContext);

  const token = user?.token || Cookies.get("token");

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  const isUserAWoman = useMemo(() => userProps.find((p) => p.name === "GENDR")?.value === "woman", [userProps]);

  const v1 = isUserAWoman ? DEFAULT_VOICES[0] : DEFAULT_VOICES[1];
  const v2 = isUserAWoman ? DEFAULT_VOICES[1] : DEFAULT_VOICES[0];

  const v = voice ?? sentence?.voice ?? (sentence?.isLeft || isSecond ? v1 : v2);

  const audio = useMemo(() => new Audio(EMPTY_AUDIO_SRC), []);

  useEffect(() => {
    if (sentence?.text && sentence?.id && !disabled) {
      audio.src = `${API_URL}/speech/sent?sentId=${sentence.id}&voice=${v}&token=${token}`;
    } else if (!sentence?.text) {
      audio.src = EMPTY_AUDIO_SRC;
    }
  }, [audio, sentence, token, v, disabled]);

  return audio;
};
export default useSentenceAudio;

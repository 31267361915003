import { API_URL, DEFAULT_VOICES } from "App.constants";
import { useContext, useMemo } from "react";
import { SentenceType } from "App.types";
import Cookies from "js-cookie";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { UserContext } from "App";

const useSentencesAudios = ({ sentences }: { sentences: SentenceType[] }) => {
  const user = useContext(UserContext);

  const token = user?.token || Cookies.get("token");

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  const isUserAWoman = useMemo(() => userProps.find((p) => p.name === "GENDR")?.value === "woman", [userProps]);

  const v1 = isUserAWoman ? DEFAULT_VOICES[0] : DEFAULT_VOICES[1];
  const v2 = isUserAWoman ? DEFAULT_VOICES[1] : DEFAULT_VOICES[0];

  return useMemo(
    () =>
      sentences.map(
        (s) =>
          new Audio(
            s.text?.trim() &&
              `${API_URL}/speech/sent?sentId=${s.id}&voice=${s.voice ?? (s.isLeft ? v1 : v2)}&token=${token}&style=${s.voiceStyle ?? ""}`,
          ),
      ),
    [sentences, token, v1, v2],
  );
};

export default useSentencesAudios;

import { useQuery } from "@tanstack/react-query";
import API from "Api";
import { Levels, STATIC_URL } from "App.constants";
import cx from "classnames";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Badge, Flex, Progress, ProgressProps, Tag } from "antd";
import styles from "./Courses.module.scss";
import { isNumber } from "lodash";
import React, { useCallback, useContext, useMemo } from "react";
import ButtonClose from "Components/ButtonClose";
import Cookies from "js-cookie";
import { UserContext } from "App";
import { LockOutlined } from "@ant-design/icons";
import { encodeS3URI } from "App.helpers";
import { LessonType } from "App.types";

const twoColors: ProgressProps["strokeColor"] = {
  "0%": "#108ee9",
  "100%": "#87d068",
};

const Courses = () => {
  const user = useContext(UserContext);

  const { data: courses = [] } = useQuery({
    queryKey: ["courses", user?.isAdmin],
    queryFn: API.course.getList,
    staleTime: Infinity,
  });

  const { data: progress = [] } = useQuery({
    queryKey: ["progress"],
    queryFn: () => API.progress.getForCourse(undefined),
  });

  const courseProgress = useMemo<{ [id: number]: number | undefined }>(
    () =>
      courses.reduce(
        (acc, cur: any) => ({
          ...acc,
          [cur.id]:
            (100 *
              progress.filter((pr) => cur.lessons?.some((l: LessonType) => l.id === pr.lesson.id) && pr.status === "completed").length) /
            cur.lessons?.length,
        }),
        {},
      ),
    [courses, progress],
  );

  const isWelcomeCompleted = courseProgress?.[30] === 100;

  const navigate = useNavigate();

  const onClick = useCallback(
    (description: string, title: string, id: number) => {
      API.user
        .update({ activeCourse: id })
        .then(() => Cookies.set("user", JSON.stringify({ ...user, activeCourse: id }), { expires: 24 * 60 * 60 * 31 }))
        .then(() => navigate(description === "grammar" ? `/course/grammar/${title}` : `/course/${title}`));
    },
    [navigate, user],
  );

  return (
    <div className={styles.courses}>
      <Flex justify={"space-between"}>
        <header className={styles.courses__header}>Мои курсы</header>
        <ButtonClose goBack />
      </Flex>

      <div className={styles.list}>
        {courses?.map(({ id, picture, grammar, title, description = "", translate, level, isActive }) => (
          <Badge.Ribbon
            key={id}
            text={id === 30 ? undefined : isWelcomeCompleted ? isNumber(level) ? Levels[level]?.grade : undefined : <LockOutlined />}
            color={isNumber(level) ? Levels[level]?.color : undefined}
            rootClassName={cx(styles.card, styles.card_courses, {
              [styles.card__locked]: ![30, 25, 39].includes(id) && !isWelcomeCompleted,
              [styles.card_mobile]: isMobile,
            })}
            className={cx({ [styles.ribbon__hidden]: !(isNumber(level) && Levels[level]?.title) })}
          >
            <div className={styles.content} onClick={() => onClick(description, title, id)}>
              {!isMobile && (
                <div
                  className={styles.picture}
                  style={{ backgroundImage: picture && `url(${STATIC_URL}/covers/${encodeS3URI(picture)})` }}
                ></div>
              )}
              <div className={styles.content__right}>
                <div className={styles.card__title}>
                  {title}
                  {!isActive && <Tag color={"red"}>inactive</Tag>}
                </div>
                <div className={styles.card__description}>{translate}</div>
                <div dangerouslySetInnerHTML={{ __html: grammar?.content || "" }}></div>

                {!!courseProgress?.[id] && (
                  <Progress
                    className={styles.courseProgress}
                    size={"small"}
                    percent={courseProgress?.[id]}
                    showInfo={courseProgress[id] === 100}
                    strokeColor={twoColors}
                  />
                )}
              </div>
            </div>
          </Badge.Ribbon>
        ))}
      </div>
    </div>
  );
};

export default Courses;

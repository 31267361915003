import { Avatar, Button, Dropdown, Layout, Modal, notification, Result, Skeleton, Space, Tag, theme, Tooltip } from "antd";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { createContext, Dispatch, FC, SetStateAction, Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AreaChartOutlined, CrownTwoTone, DownOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import styles from "./MainLayout.module.scss";
import { googleLogout } from "@react-oauth/google";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Levels } from "App.constants";
import API from "Api";
import { isMobile } from "react-device-detect";
import CountUp from "react-countup";
import { AxiosInstance } from "axios";
import cx from "classnames";
import LoginModal from "./LoginModal";
import { PremiumType, UserType } from "App.types";
import { init, isTMA, retrieveLaunchParams } from "@telegram-apps/sdk-react";
import Premium from "Components/Premium";
import { Fireworks, FireworksHandlers } from "@fireworks-js/react";
import { delay } from "lodash";

const { Header, Content } = Layout;

export const PremiumContext = createContext<{
  premium: PremiumType | undefined;
  showModal: (success: () => any, error?: () => any) => any;
}>({
  premium: undefined,
  showModal: () => {},
});

const MainLayout: FC<{
  axiosInstance: AxiosInstance;
  setUser: Dispatch<SetStateAction<UserType | undefined>>;
  user: UserType | undefined;
}> = ({ axiosInstance, setUser, user }) => {
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const premiumCallbackRef = useRef<() => any>();
  const premiumInterruptRef = useRef<() => any>();

  // useEffect(() => {
  //   if (token && token.length > 100) {
  //     setUser(jwt_decode(token));
  //   } else if (token) {
  //     axios.get("https://login.yandex.ru/info", { params: { oauth_token: token, format: "json" } }).then(({ data }) => {
  //       setUser({ name: data.display_name, picture: `https://avatars.yandex.net/get-yapic/${data.default_avatar_id}/islands-middle` });
  //     });
  //   }
  // }, [token]);

  // const [collapsed, setCollapsed] = useState(false);
  // const [mobileMenu, setMobileMenu] = useState(false);

  const { id, title } = useParams();
  const { pathname } = useLocation();

  const [notifyApi, context] = notification.useNotification({ stack: { threshold: 1 } });

  const showHeader = useMemo(() => !((id || title) && isMobile) && !pathname.includes("/review/"), [id, title, pathname]);

  const openNotification = useCallback(
    (description: string) => {
      notifyApi.destroy("api");
      notifyApi.error({ key: "api", message: "", description });
    },
    [notifyApi],
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        // Cookies.remove("token");
        // Cookies.remove("user");
        setUser(undefined);
      } else {
        openNotification(error.message);
      }
      return Promise.reject(error);
    },
  );

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { data: userInfo } = useQuery({ queryKey: ["user", user?.email], queryFn: API.user.get, staleTime: Infinity });
  const { data: premium } = useQuery({ queryKey: ["premium", user?.email], queryFn: API.premium.get, staleTime: Infinity });

  const { data: wordCount = 0 } = useQuery({
    queryKey: ["wordCount"],
    queryFn: API.progress.getVocabularyCount,
    // enabled: !!user,
    staleTime: Infinity,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          // Cookies.remove("user");
          // Cookies.remove("token")
          setUser(undefined);
        } else {
          openNotification(error.message);
        }
        return Promise.reject(error);
      },
    );
  }, [user, openNotification, setUser, axiosInstance]);

  const onLogout = () => {
    googleLogout();
    setUser(undefined);
    Cookies.remove("user");
    navigate("/");
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isTMA() && location.pathname === "/") {
      init();

      const { tgWebAppData, tgWebAppStartParam } = retrieveLaunchParams();

      if (tgWebAppData?.start_param || tgWebAppStartParam) {
        navigate(`/redirect/lesson/${tgWebAppData?.start_param || tgWebAppStartParam}`);
      }
    }
  }, [location.pathname, navigate]);

  const contextValue = useMemo(
    () => ({
      premium,
      showModal: (callback: () => any, interrupt?: () => any) => {
        premiumCallbackRef.current = callback;
        premiumInterruptRef.current = interrupt;
        setShowPremiumModal(true);
      },
    }),
    [premium],
  );

  const fireworkRef = useRef<FireworksHandlers>(null);

  useEffect(() => {
    if (successModal) {
      fireworkRef?.current?.start();
      delay(() => fireworkRef?.current?.waitStop(), 5000);
    }
  }, [successModal]);

  return (
    <Layout className={cx(styles.mainLayout, { [styles.mainLayout_fullWidth]: location.pathname.includes("/review/") })}>
      <PremiumContext.Provider value={contextValue}>
        {/*<Drawer className={styles.drawer} open={mobileMenu} placement={"left"} onClose={() => setMobileMenu(false)}>*/}
        {/*  <Menu onSelect={() => setMobileMenu(false)} theme={"light"} mode={"inline"} items={items} />*/}
        {/*</Drawer>*/}

        {!user && <Header className={styles.header} style={{ background: colorBgContainer }}></Header>}

        {!user && <LoginModal axiosInstance={axiosInstance} open={!user} setUser={setUser} />}

        {showHeader && user?.name && (
          <Header className={styles.header} style={{ background: colorBgContainer }}>
            {/*<Button className={styles.header__menuIcon} type="primary" onClick={() => setMobileMenu(!mobileMenu)}>*/}
            {/*  {mobileMenu ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}*/}
            {/*</Button>*/}

            <div className={cx(styles.header__container, { [styles.header__container_mobile]: isMobile })}>
              <div className={styles.user}>
                {/*<Tooltip title={`Текущий уровень: ${Levels[userInfo?.level || 0].title}`} color={"lime"}>*/}
                {/*  <div className={styles.level}>{Levels[userInfo?.level || 0].grade}</div>*/}
                {/*</Tooltip>*/}
                {!isMobile && !premium && (
                  <Button onClick={() => setShowPremiumModal(true)} className={styles.btnPremium} color={"primary"} variant={"filled"}>
                    Premium
                  </Button>
                )}
                <Avatar
                  size={"large"}
                  src={
                    user?.picture?.includes("http") ? user?.picture : `https://avatars.yandex.net/get-yapic/${user?.picture}/islands-middle`
                  }
                />
                <div className={styles.user_name}>
                  <Dropdown
                    menu={{
                      items: [
                        { key: 0, label: "Статистика", icon: <AreaChartOutlined /> },
                        { key: 3, label: "👑  Премиум", icon: "", onClick: () => setShowPremiumModal(true) },
                        { key: 1, label: "Профиль", icon: <UserOutlined />, onClick: () => navigate("/profile") },
                        { key: 2, label: "Выход", icon: <LogoutOutlined />, onClick: onLogout },
                      ],
                    }}
                  >
                    <div>
                      <span>{isMobile ? user?.name?.split("@")?.[0] : user?.name}</span>
                      <DownOutlined />
                    </div>
                  </Dropdown>
                  <span style={{ fontSize: 12, marginRight: 5 }}>Level:</span>
                  <Tag style={{ fontSize: 12 }} color={"green"}>
                    {Levels[userInfo?.level || 0].title}
                  </Tag>
                </div>
              </div>

              <div>
                <Space className={styles.vocabulary}>
                  {premium ? "👑" : <CrownTwoTone />}
                  <div className={styles.counter} onClick={() => navigate("/vocabulary")}>
                    <Tooltip title={"твой словарный запас"}>
                      <CountUp end={wordCount} />
                    </Tooltip>
                  </div>
                </Space>
              </div>
              {/*<Button className={styles.buttonLogout} type={"link"} icon={<LogoutOutlined />} onClick={onLogout}></Button>*/}
            </div>
          </Header>
        )}
        <Content className={styles.container}>
          <div className={cx(styles.content, { [styles.content__withoutHeader]: !showHeader })}>
            {user && (
              <Suspense fallback={<Skeleton active loading />}>
                <Outlet />
              </Suspense>
            )}
          </div>
        </Content>
        {/*<Footer style={{ textAlign: "center" }}>www.lexoid.ru 2023</Footer>*/}
        {context}

        <Modal
          footer={null}
          width={930}
          open={showPremiumModal}
          onCancel={() => {
            setShowPremiumModal(false);
            premiumInterruptRef.current?.();
          }}
        >
          <Premium
            premium={premium}
            setUser={setUser}
            onCancel={premiumInterruptRef.current}
            onComplete={() => {
              setShowPremiumModal(false);
              setSuccessModal(true);
            }}
          />
        </Modal>

        <Fireworks
          ref={fireworkRef}
          autostart={false}
          options={{ acceleration: 1 }}
          style={{ position: "fixed", height: "100%", width: "100%", left: 0, top: 0, pointerEvents: "none", zIndex: 999999 }}
        />

        <Modal
          closable={false}
          footer={null}
          open={successModal}
          onCancel={() => {
            setSuccessModal(false);
            premiumCallbackRef.current?.();
          }}
        >
          <Result
            status="success"
            title={
              <div>
                <div>✨ Поздравляем! ✨</div>
                <div>Теперь у тебя есть премиум.</div>
              </div>
            }
            extra={
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  setSuccessModal(false);
                  premiumCallbackRef.current?.();
                }}
              >
                Let's go!
              </Button>
            }
          />
        </Modal>
      </PremiumContext.Provider>
    </Layout>
  );
};

export default MainLayout;

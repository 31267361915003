import React, { Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "App";
import { Badge, Button, Card, Collapse, Descriptions, Flex, Input, Modal, notification } from "antd";
import { isMobile } from "react-device-detect";
import API from "Api";
import dayjs from "dayjs";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import styles from "Components/Premium/Premium.module.scss";
import useScript from "Components/useScript";
import { PremiumType, PromoType, UserType } from "App.types";
import cx from "classnames";
import "dayjs/locale/ru";

const Premium: FC<{
  onComplete?: () => any;
  onCancel?: () => any;
  premium: PremiumType | undefined;
  setUser: Dispatch<SetStateAction<UserType | undefined>>;
}> = ({ premium, onComplete, onCancel }) => {
  const [widget, setWidget] = useState<any>();
  const [promo, setPromo] = useState<PromoType>();
  const [confirmCancel, setConfirmCancel] = useState(false);

  const user = useContext(UserContext);
  const [notify, context] = notification.useNotification();

  const onWidgetLoad = useCallback(() => {
    setWidget(new (window as any).cp.CloudPayments());
  }, []);

  useEffect(() => {
    setPromo(undefined);
  }, [user]);

  const { data: subscription } = useQuery({
    enabled: !!premium?.subscriptionId,
    queryKey: ["subscription", user?.email],
    queryFn: API.premium.subscription,
  });

  const client = useQueryClient();

  useScript("https://widget.cloudpayments.ru/bundles/cloudpayments.js", onWidgetLoad);

  const onSubscribe = useCallback(
    (options: any) => {
      widget.pay(
        "charge", // или 'auth' для двухстадийной оплаты
        {
          // publicId: "pk_9274435206826342209f5befcbe45", // для тестов
          publicId: "pk_b6d7f58f8f9a7f7329d2ad350ab4c",
          description: options.description, //назначение
          amount: options.amount, //сумма
          currency: "RUB", //валюта
          accountId: user?.email, //идентификатор плательщика (обязательно при подписке)
          // invoiceId: "1234567", //номер заказа  (необязательно)
          email: user?.email, //email плательщика (необязательно)
          // skin: "mini", //дизайн виджета (необязательно)
          data: options.monthly
            ? {
                cloudpayments: {
                  recurrent: {
                    interval: "Month",
                    period: options.months,
                    startDate: options.nextPayment,
                    amount: options.recurrentAmount || options.amount,
                  },
                },
              }
            : { to: options.nextPayment, price: options.price },
        },
        {
          onSuccess: function (options: any) {
            client.invalidateQueries({ queryKey: ["premium", user?.email] });
            onComplete?.();
          },
          onFail: function (reason: any, options: any) {
            console.error(reason);
            onCancel?.();
            // notify.error({ message: "", description: reason });
          },
          onComplete: function (paymentResult: any, options: any) {
            //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
            //например вызов вашей аналитики Facebook Pixel
          },
        },
      );
    },
    [widget, user?.email, client, onComplete, onCancel],
  );

  const onPromo = useCallback(
    (code: string) => {
      API.promo.findCode(code.trim()).then((data) => {
        if (data) {
          notify.success({ message: "Промокод применен!" });
        } else {
          notify.error({ message: "Промокод не найден!" });
        }
        setPromo(data);
      });
    },
    [notify],
  );

  const offers = useMemo(
    () => [
      {
        months: undefined,
        description: "Пробный премиум на 7 дней",
        amount: promo?.months === null ? promo.amount : 299,
        price: 299,
        discountAmount: promo?.months === null ? promo.amount : undefined,
        badge: promo?.months === null ? promo.title : undefined,
        info: promo?.months === null ? promo.description : undefined,
        monthly: false,
        nextPayment: dayjs().add(1, "week"),
      },
      {
        months: 3,
        description: "Премиум подписка 3 месяца",
        amount: 1990,
        price: 663,
        discountAmount: promo?.months === 3 ? promo.amount : undefined,
        info: promo?.months === 3 ? promo.description : "C вашего счета будет списано 1990 руб за 3 месяца доступа",
        badge: promo?.months === 3 ? promo.title : "выгодно!",
        monthly: true,
        nextPayment: dayjs().add(3, "month"),
      },
      {
        months: 1,
        description: "Премиум подписка на месяц",
        badge: promo?.months === 1 ? promo.title : undefined,
        discountAmount: promo?.months === 1 ? promo.amount : undefined,
        amount: 990,
        price: 990,
        monthly: true,
        info: promo?.months === 1 ? promo.description : undefined,
        nextPayment: dayjs().add(1, "month"),
      },
    ],
    [promo],
  );

  const endOfPremium = dayjs(premium?.to)
    .locale("ru")
    .format("D MMMM YYYY");

  return (
    <div className={styles.premium}>
      {premium ? (
        <Card title={"✨ Премиум доступ  ✨"}>
          <Descriptions
            size={"small"}
            layout={"vertical"}
            items={[
              { key: "4", label: "Тариф", children: premium.title },
              {
                key: "1",
                label: "Периодичность",
                children: premium.subscriptionId ? (subscription?.Model.Period === 3 ? "Каждые 3 месяца" : "Ежемесячно") : "Разовая",
              },
              { key: "0", label: "Статус", children: premium.cancelled && premium.subscriptionId ? "Отменена" : "Активна" },
              {
                key: "3",
                label: "Стоимость ",
                children: subscription?.Model
                  ? `${subscription?.Model.Amount} ${subscription?.Model.Currency}`
                  : `${premium.amount} ${premium.currency}`,
              },
              { key: "2", label: premium.cancelled ? "Активна до" : "Следующий платеж", children: endOfPremium },
            ]}
          />

          {premium.subscriptionId && (
            <Flex justify={"end"}>
              {premium.cancelled ? (
                <Button
                  onClick={() =>
                    API.premium.return().then(() => {
                      client.invalidateQueries({ queryKey: ["premium", user?.email] });
                      notify.success({ message: "Подписка возобновлена!" });
                    })
                  }
                  size={isMobile ? "middle" : undefined}
                  color={"primary"}
                  variant={"outlined"}
                >
                  возобновить
                </Button>
              ) : (
                <Button onClick={() => setConfirmCancel(true)} size={isMobile ? "middle" : undefined} color={"danger"} variant={"outlined"}>
                  отменить
                </Button>
              )}
            </Flex>
          )}
        </Card>
      ) : (
        <div>
          <div className={styles.cards}>
            {offers.map((offer, idx) => (
              <Badge.Ribbon key={idx} text={offer.badge} color={offer.badge ? "red" : "transparent"}>
                <Card className={styles.card}>
                  <h3>{offer.description}</h3>

                  <ul className={styles.list}>
                    <li>Доступ ко всем курсам и урокам</li>
                    <li>Выполнение речевых заданий</li>
                    <li>Разговоры с Lexoid AI</li>
                  </ul>

                  <div className={styles.price}>
                    <span className={cx(styles.amount, { [styles.amount__discount]: !!offer.discountAmount })}>
                      {offer.price} {!!offer.discountAmount && " ₽"}
                    </span>

                    {offer.discountAmount && <span className={cx(styles.amount)}> {offer.discountAmount}</span>}

                    {offer.monthly ? " ₽ / мес" : " ₽ "}
                  </div>
                  <div className={styles.card__info}>{offer.info}</div>

                  <Button type={"primary"} block onClick={() => onSubscribe(offer)}>
                    Подписаться
                  </Button>
                </Card>
              </Badge.Ribbon>
            ))}
          </div>
          <Collapse
            ghost
            accordion
            items={[
              {
                label: "У меня есть промокод",
                children: <Input.Search onSearch={onPromo} placeholder={"номер промокода"} enterButton={"OK"} />,
              },
            ]}
          />
        </div>
      )}

      {context}

      <Modal
        okButtonProps={{ color: "danger", variant: "filled" }}
        okText={"Отменить"}
        cancelText={"Закрыть"}
        open={confirmCancel}
        onCancel={() => {
          onCancel?.();
          setConfirmCancel(false);
        }}
        onOk={() =>
          API.premium.cancel().then(() => {
            client.invalidateQueries({ queryKey: ["premium", user?.email] });
            setConfirmCancel(false);
            notify.warning({ message: "Подписка отменена" });
          })
        }
        title={"Отменить подписку"}
      >
        Вы уверены, что хотите отменить подписку? <br />
        <br />
        При отмене подписки она будет активна только до конца текущего оплаченного периода - <strong>{endOfPremium}</strong>. По истечении
        срока у вас больше не будет доступа к платным курсам, урокам и дополнительным возможностям подписки.
      </Modal>
    </div>
  );
};

export default Premium;

import { Button, Flex, Space } from "antd";
import { FC, useContext } from "react";
import { ReactComponent as NeedPremiumSvg } from "./need_premium.svg";
import styles from "./NeedPremium.module.scss";
import { PremiumContext } from "Components/MainLayout";
import { CrownOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";

const NeedPremium: FC<{ onInterrupt?: () => any; onClose: () => any; onCancel: () => any; onComplete?: () => any }> = ({
  onCancel,
  onClose,
  onInterrupt,
  onComplete = () => {},
}) => {
  const premiumContext = useContext(PremiumContext);

  return (
    <div className={styles.needPremium}>
      <Flex align={"center"}>
        <NeedPremiumSvg />
        <div className={styles.title}>Для продолжения требуется премиум подписка</div>
      </Flex>

      <Flex justify={"flex-end"}>
        <Space style={{ width: isMobile ? "100%" : undefined }} direction={isMobile ? "vertical" : "horizontal"}>
          <Button block onClick={onCancel}>
            Пропустить
          </Button>
          <Button
            block
            onClick={() => {
              onClose();
              premiumContext.showModal(onComplete, onInterrupt);
            }}
            icon={<CrownOutlined />}
            color={"primary"}
            variant={"solid"}
          >
            Оформить
          </Button>
        </Space>
      </Flex>
    </div>
  );
};

export default NeedPremium;
